import { Module } from 'vuex';
import * as firebase from 'firebase/app';
import 'firebase/auth';
import { router } from '@/router';
import Vue from 'vue';

export interface AuthState {
  user: {
    loggedIn: boolean;
    data: any;
    group?: string;
    isSuperUser?: boolean;
    theme: any;
    baseURL: string;
    showTVControls: boolean;
    isAdvancedTVOutputEnabled: boolean;
  };
}

const AuthModule: Module<AuthState, any> = {
  namespaced: true,
  state: {
    user: {
      loggedIn: false,
      data: null,
      userGroup: undefined,
      theme: {},
      baseURL: '',
      showTVControls: false,
      isAdvancedTVOutputEnabled: false,
    },
  } as AuthState,
  getters: {
    getUser: (state) => state.user,
    isUserAuthenticated: (state) => state.user.loggedIn,
    getUserId: (state) => (state.user.data ? state.user.data.uid : null),
    getUserGroup: (state) => state.user.group,
    isSuperUser: (state) => !!state.user.isSuperUser,
    getUserTheme: (state) => state.user.theme,
    getUserBaseURL: (state) => state.user.baseURL,
    getUserShowTVControls: (state) => state.user.showTVControls,
    isAdvancedTVOutputEnabled: (state) => state.user.isAdvancedTVOutputEnabled,
  },
  mutations: {
    SET_LOGGED_IN(state, payload) {
      state.user.loggedIn = payload;
    },
    SET_USER(state, payload) {
      state.user.data = payload;
    },
    SET_USER_GROUP(state, payload) {
      state.user.group = payload;
    },
    SET_SUPER_USER(state, payload) {
      Vue.set(state.user, 'isSuperUser', payload);
    },
    SET_USER_THEME(state, payload) {
      Vue.set(state.user, 'theme', payload);
    },
    SET_USER_BASE_URL(state, payload) {
      Vue.set(state.user, 'baseURL', payload);
    },
    SET_USER_SHOW_TV_CONTROLS(state, payload) {
      Vue.set(state.user, 'showTVControls', payload);
    },
    SET_USER_ADVANCED_TV_OUTPUT_ENABLED(state, payload) {
      Vue.set(state.user, 'isAdvancedTVOutputEnabled', payload);
    },
    RESET(state) {
      state.user = {
        loggedIn: false,
        data: null,
        theme: {},
        baseURL: '',
        showTVControls: false,
        isAdvancedTVOutputEnabled: false,
      };
    },
  },
  actions: {
    autoSignIn({ commit, dispatch }, user: any) {
      commit('SET_LOGGED_IN', user != null);
      if (user.isAnonymous) {
        commit('SET_USER', {
          uid: user.uid,
          isAnonymous: user.isAnonymous,
        });
      } else {
        commit('SET_USER', {
          displayName: user.displayName,
          email: user.email,
          uid: user.uid,
          isAnonymous: user.isAnonymous,
        });
        dispatch('fetchUserDetails', user.uid);
      }
    },
    async signInWithEmail({ commit, dispatch }, { email, password }) {
      // const captchaVerified = await verifyCaptchaToken(captchaToken);
      // if (!captchaVerified) {
      //   commit('SET_ERROR_MESSAGE', 'Captcha verification failed.', {
      //     root: true,
      //   });
      //   return;
      // }
      firebase
        .auth()
        .signInWithEmailAndPassword(email, password)
        .then((data: any) => {
          if (data.user) {
            dispatch('autoSignIn', data.user);
            if (router.currentRoute.query.redirect) {
              router.replace(router.currentRoute.query.redirect as string);
            } else {
              router.replace({ name: 'Presenter Home' });
            }
          } else {
            commit('SET_ERROR_MESSAGE', 'Error. Please get in touch with us', {
              root: true,
            });
          }
        })
        .catch((err) => {
          commit('SET_ERROR_MESSAGE', err.message, { root: true });
        });
    },
    async registerUser({ commit, dispatch }, { email, password, name }) {
      // const captchaVerified = await verifyCaptchaToken(captchaToken);
      // if (!captchaVerified) {
      //   commit('SET_ERROR_MESSAGE', 'Captcha Verification failed.', {
      //     root: true,
      //   });
      //   return;
      // }
      firebase
        .auth()
        .createUserWithEmailAndPassword(email, password)
        .then((data: any) => {
          data.user
            .updateProfile({
              displayName: name,
              photoURL:
                'https://images.unsplash.com/photo-1502685104226-ee32379fefbe?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
            })
            .then(() => {
              const db = firebase.firestore();
              const presentersRef = db.collection('presenters');
              presentersRef
                .doc(data.user.uid)
                .set({
                  _id: data.user.uid,
                  username: data.user.displayName,
                  email: data.user.email,
                  group: 'reaction.link',
                  avatar:
                    'https://images.unsplash.com/photo-1502685104226-ee32379fefbe?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
                })
                .then(() => {
                  dispatch('autoSignIn', data.user);
                  router.replace({ name: 'Presenter Home' });
                })
                .catch((err) => {
                  commit('SET_ERROR_MESSAGE', err.message, { root: true });
                });
            });
        })
        .catch((err) => {
          commit('SET_ERROR_MESSAGE', err.message, { root: true });
        });
    },
    async signOut() {
      await firebase.auth().signOut();
      router.replace('/presenter/signout');
    },
    signInAnonymously({ commit, state }) {
      if (!state.user.loggedIn && !state.user.data) {
        firebase
          .auth()
          .signInAnonymously()
          .then((data: any) => {
            commit('SET_LOGGED_IN', data.user != null);
            if (data.user) {
              commit('SET_USER', {
                uid: data.user.uid,
                isAnonymous: data.user.isAnonymous,
              });
            }
          })
          .catch((error) => {
            // Handle Errors here.
          });
      }
    },
    fetchUserDetails({ commit }, userId) {
      const db = firebase.firestore();
      const userRef = db.collection('presenters').doc(userId);
      userRef
        .get()
        .then((doc) => {
          if (doc.exists) {
            const userData = doc.data();
            if (userData) {
              if ('group' in userData!)
                commit('SET_USER_GROUP', userData!.group);

              commit('SET_SUPER_USER', !!userData.isSuperUser);
              commit('SET_USER_THEME', userData.theme);
              commit('SET_USER_BASE_URL', userData.baseURL);
              commit('SET_USER_SHOW_TV_CONTROLS', userData.showTVControls);
              commit(
                'SET_USER_ADVANCED_TV_OUTPUT_ENABLED',
                !!userData.isAdvancedTVOutputEnabled
              );
            }
          } else {
            console.log('No such document!');
          }
        })
        .catch(function (error) {
          console.log('Error getting document:', error);
        });
    },
  },
};

export { AuthModule };
