import * as firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/storage';
import { Module } from 'vuex';
import { nanoid } from 'nanoid';
import { router } from '@/router';
import { firestoreAction } from 'vuexfire';
import { PollType, PresenterPoll } from '@/api/interfaces.api';
import PresenterSession from '@/dtos/session';
import Vue from 'vue';
import ThemeModel from '@/dtos/theme';

export interface PresenterState {
  sessions: PresenterSession[];
  unlimitedSessions: PresenterSession[];
  shareURL?: string;
  currentSessionId?: string;
  currentSession?: PresenterSession;
  timelineData?: any[];
  sessionPolls: PresenterPoll[];
  pollCategories: any[];
  pollAnswers: any[];
  pollComments: any[];
  selectedPollComments: any[];
  pollGroupResult: any;
  sessionWordclouds: any[];
  sessionLabels: { [id: string]: string };
  sessionDescriptions: { [id: string]: string };
  sessionButtonLabels: { [id: string]: string };
  sessionThemes: ThemeModel[];
  sessionComments: any[];
  loading: boolean;
  tvData: any;
  pollTVData: any;
  internal: {
    commentRequest: any;
  };
}

const PresenterModule: Module<PresenterState, any> = {
  namespaced: true,
  state: {
    sessions: [],
    unlimitedSessions: [],
    shareURL: '',
    currentSessionId: undefined,
    currentSession: undefined,
    timelineData: [],
    sessionPolls: [],
    pollCategories: [],
    pollAnswers: [],
    pollComments: [],
    selectedPollComments: [],
    sessionWordclouds: [],
    sessionLabels: {},
    sessionDescriptions: {},
    sessionButtonLabels: {},
    sessionThemes: [],
    sessionComments: [],
    loading: true,
    tvData: {},
    pollTVData: {},
    pollGroupResult: {},
    internal: {
      commentRequest: {},
    },
  } as PresenterState,
  getters: {
    getSessions: (state) => {
      if (state.sessions && state.sessions.length > 0) {
        const res = state.sessions.filter(
          (session: any) => !session.sessionUnlimited
        );
        return res;
      }
      return [];
    },
    getUnlimitedSessions: (state) => state.unlimitedSessions,
    getShareURL: (state) => state.shareURL,
    getCurrentSession: (state) => state.currentSession || {},
    getSessionShareToken: (state) => state.currentSession?.shareToken || '',
    getPastSessions: (state) => {
      if (state.sessions && state.sessions.length > 0) {
        const res = state.sessions.filter(
          (session: any) => !session.sessionUnlimited
        );
        return res;
      }
      return [];
    },
    getTimelineData: (state) => state.timelineData,
    getSessionPolls: (state) =>
      state.sessionPolls.filter(
        (poll) =>
          !poll.group &&
          !poll.id.includes('progress') &&
          poll.type !== PollType.Form &&
          !poll.isHiddenInAdminPanel
      ),
    getSessionComments: (state) => state.sessionComments,
    getGroupPolls: (state) => (groupId: string) =>
      state.sessionPolls
        .filter(
          (poll) =>
            poll.group &&
            poll.group === groupId &&
            !poll.id.includes('progress') &&
            poll.type !== PollType.Form
        )
        .sort((a, b) => {
          if (a.order < b.order) return -1;
          if (a.order > b.order) return 1;
          return 0;
        }),
    getPollById: (state) => (pollId: string) =>
      state.sessionPolls.filter((poll) => poll.id === pollId)[0],
    getPollAnswers: (state) => state.pollAnswers,
    getSessionWordclouds: (state) => state.sessionWordclouds,
    getCurrentPoll: (state) => (pollId: string) =>
      state.sessionPolls.filter((poll: PresenterPoll) => poll.id === pollId),
    getLabels: (state) => state.sessionLabels,
    getDescriptions: (state) => state.sessionDescriptions,
    getButtonLabels: (state) => state.sessionButtonLabels,
    getSessionThemes: (state) =>
      state.sessionThemes.map((theme: ThemeModel) => theme.name),
    getPollCategories: (state) => state.pollCategories,
    getPollComments: (state) => state.pollComments,
    getSelectedPollComments: (state) => state.selectedPollComments,
    getLoadingState: (state) => state.loading,
    getTVData: (state) => state.tvData,
    getPollTVData: (state) => state.pollTVData,
    getPollGroupResult: (state) => state.pollGroupResult,
  },
  mutations: {
    SET_SHARE_URL(state, payload) {
      state.shareURL = payload;
    },
    SET_CURRENT_SESSION_ID(state, payload) {
      state.currentSessionId = payload;
    },
    SET_CURRENT_SESSION(state, payload) {
      state.currentSession = payload;
    },
    SET_SESSION_LABEL(state, { label, labelId }) {
      Vue.set(state.sessionLabels, labelId, label);
    },
    SET_SESSION_COMMENTS(state, comments) {
      state.sessionComments = comments;
    },
    COMBINE_SESSION_COMMENTS(state, comments) {
      if (comments.length > 0) {
        state.sessionComments = state.sessionComments.concat(comments);
      }
    },
    SET_POLL_ANSWERS(state, answers) {
      Vue.set(state, 'pollAnswers', answers);
    },
    SET_POLL_COMMENTS(state, comments) {
      state.pollComments = comments;
    },
    COMBINE_POLL_COMMENTS(state, comments) {
      if (comments.length > 0) {
        state.pollComments = state.pollComments.concat(comments);
      }
    },
    SET_LOADING_STATE(state, loading) {
      Vue.set(state, 'loading', loading);
    },
    REMOVE_SESSION_LABEL(state, labelId) {
      delete state.sessionLabels[labelId];
    },
    SET_SESSION_DESCRIPTION(state, { description, descriptionId }) {
      Vue.set(state.sessionDescriptions, descriptionId, description);
    },
    REMOVE_SESSION_DESCRIPTION(state, descriptionId) {
      delete state.sessionDescriptions[descriptionId];
    },
    SET_SESSION_BUTTON_LABEL(state, { buttonLabel, buttonId }) {
      Vue.set(state.sessionButtonLabels, buttonId, buttonLabel);
    },
    REMOVE_SESSION_BUTTON_LABEL(state, buttonId) {
      delete state.sessionButtonLabels[buttonId];
    },
    RESET_POLL_COMMENTS(state) {
      state.pollComments = [];
      state.selectedPollComments = [];
      state.pollTVData = {};
    },
    SET_POLL_GROUP_RESULT(state, payload) {
      state.pollGroupResult = payload;
    },
    SET_COMMENT_REQUEST(state, payload) {
      Vue.set(state.internal, 'commentRequest', payload);
    },
    RESET(state) {
      state.sessions = [];
      state.unlimitedSessions = [];
      state.shareURL = '';
      state.currentSessionId = undefined;
      state.currentSession = undefined;
      state.timelineData = [];
      state.sessionPolls = [];
      state.sessionWordclouds = [];
      state.sessionLabels = {};
      state.sessionDescriptions = {};
      state.sessionButtonLabels = {};
      state.loading = false;
      state.tvData = {};
      state.pollTVData = {};
      state.pollGroupResult = {};
    },
  },
  actions: {
    async createSession({ commit, rootGetters }, { form, callback }) {
      const {
        name,
        description,
        startAt,
        endAt,
        sessionUnlimited,
        theme,
      } = form;
      const userData = rootGetters['auth/getUser'];
      const { uid, displayName } = userData.data;
      const isSuperUser = !!userData.isSuperUser;
      const userGroup = isSuperUser ? theme : userData.group;
      const newSession = PresenterSession.fromDict({
        name,
        startAt,
        endAt,
        sessionUnlimited,
        link: '',
        description,
        author: uid,
        authorName: displayName,
        userGroup,
      });

      // Random generate nanoid for share URL
      let shareToken = nanoid(8);
      while (shareToken.includes('I') || shareToken.includes('l')) {
        shareToken = nanoid(8);
      }
      const shareURL =
        'https://' + window.location.host + '/join/' + shareToken;
      newSession.shareToken = shareToken;

      const req = { newSession: newSession.toJSON(), uid, shareURL };
      const res: Response = await fetch('/api/createSession', {
        method: 'post',
        body: JSON.stringify(req),
        headers: {
          'Content-Type': 'application/json',
        },
      });
      const resJSON = await res.json();

      commit('SET_SHARE_URL', shareURL);
      commit('SET_CURRENT_SESSION_ID', resJSON.id);
      commit('SET_CURRENT_SESSION', newSession);
      callback();
    },

    // duplicate here
    async duplicateSession(
      { commit, rootGetters, getters },
      { form, isViewerData, callback }
    ) {
      const oldSession = getters['getCurrentSession'];
      const oldSessionId = oldSession.id;

      const {
        name,
        description,
        startAt,
        endAt,
        sessionUnlimited,
        userGroup,
      } = form;
      const userData = rootGetters['auth/getUser'];
      const { uid, displayName } = userData.data;

      const newSession = PresenterSession.fromDict({
        name,
        startAt,
        endAt,
        sessionUnlimited,
        link: '',
        description,
        author: uid,
        authorName: displayName,
        userGroup,
      });
      if (isViewerData) {
        newSession.reactions = oldSession['reactions'];
        newSession.wordcloudTopic = oldSession['wordcloudTopic'];
      }

      // Random generate nanoid for share URL
      let shareToken = nanoid(8);
      while (shareToken.includes('I') || shareToken.includes('l')) {
        shareToken = nanoid(8);
      }
      const shareURL = 'https://sterntv.reaction.link/j/' + shareToken;
      newSession.shareToken = shareToken;
      const newSessionJSON = newSession.toJSON();

      const req = {
        newSession: newSessionJSON,
        oldSessionId,
        isViewerData,
        uid,
        shareURL,
      };
      const res: Response = await fetch('/api/duplicateSession', {
        method: 'post',
        body: JSON.stringify(req),
        headers: {
          'Content-Type': 'application/json',
        },
      });
      const resJSON = await res.json();

      commit('SET_SHARE_URL', shareURL);
      commit('SET_CURRENT_SESSION_ID', resJSON.id);
      commit('SET_CURRENT_SESSION', newSession);
      callback();
    },

    async uploadFile(_, { author, folderName, fileName, file, sessionId }) {
      // Upload file and metadata to the object
      const storageRef = firebase.storage().ref();
      const uploadTask = storageRef
        .child('session-images')
        .child(author)
        .child(sessionId)
        .child(folderName)
        .put(file);

      // Listen for state changes, errors, and completion of the upload.
      await uploadTask.on(
        firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
        (snapshot) => {
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log('Upload is ' + progress + '% done');
          switch (snapshot.state) {
            case firebase.storage.TaskState.PAUSED: // or 'paused'
              console.log('Upload is paused');
              break;
            case firebase.storage.TaskState.RUNNING: // or 'running'
              console.log('Upload is running');
              break;
          }
        },
        (error) => {
          console.log(error);
        },
        () => {
          // Upload completed successfully, now we can get the download URL
          uploadTask.snapshot.ref.getDownloadURL().then(async (downloadURL) => {
            const db = firebase.firestore();
            db.collection('sessions')
              .doc(sessionId)
              .set({ [fileName]: downloadURL }, { merge: true });
          });
        }
      );
    },
    async updateSession({ getters }, form) {
      const {
        name,
        description,
        startAt,
        endAt,
        link,
        author,
        authorName,
        userGroup,
        shareToken,
        sessionUnlimited,
        theme,
        reactions,
        isPaused,
        deactivatedMenuItems,
        labels,
        descriptions,
      } = form;
      const updatedSession = PresenterSession.fromDict({
        name,
        startAt,
        endAt,
        sessionUnlimited,
        link,
        description,
        author,
        authorName,
        userGroup: theme ?? userGroup,
      });
      updatedSession.deactivatedMenuItems = deactivatedMenuItems;
      updatedSession.labels = labels;
      updatedSession.descriptions = descriptions;
      updatedSession.isPaused = isPaused;
      updatedSession.reactions = reactions;
      updatedSession.shareToken = shareToken;
      const sessionId = getters['getCurrentSession'].shareToken;

      // TODO: Reset sessionSentReportStatus to pending

      // DB Initialization
      const db = firebase.firestore();
      const presentersCollection = db.collection('presenters');
      const sessionCollection = db.collection('sessions');

      if (sessionId) {
        await sessionCollection.doc(sessionId).update(updatedSession.toJSON());
        await presentersCollection
          .doc(author)
          .collection('sessions')
          .doc(sessionId)
          .update(updatedSession.toJSON());
      }
    },
    async updateDeactivatedItems({ getters }, { deactivatedMenuItems }) {
      const sessionId = getters['getCurrentSession'].shareToken;
      if (sessionId) {
        const db = firebase.firestore();
        const ref = db.collection('sessions').doc(sessionId);
        ref.set({ deactivatedMenuItems }, { merge: true });
      }
    },
    async checkForLastSession({ rootGetters }) {
      const user = rootGetters['auth/getUser'];
      if (user.data) {
        if (user.data.uid && !user.data.isAnonymous) {
          router.replace({ name: 'Presenter Home' });
        }
      }
    },
    async fetchPresenterSession({ dispatch, commit, rootGetters }, shareToken) {
      const db = firebase.firestore();

      const sessionResponse = await db
        .collection('sessions')
        .doc(shareToken)
        .get();

      if (!sessionResponse) {
        // User has no access to the resource or resource does not exist
        commit('SET_CURRENT_SESSION', null);
        router.replace({ name: 'Presenter Home' });
      } else {
        const session = sessionResponse.data() ?? {};
        const sessionId: string = session.shareToken;
        session.id = sessionId;

        commit('SET_CURRENT_SESSION', session);

        if (process.env.NODE_ENV === 'development') {
          console.log(session.shareToken);
        }

        dispatch('bindCurrentSession', sessionId);
        dispatch('bindTVData', sessionId);
        dispatch('bindPresenterPolls', { sessionId });
        dispatch('bindPollCategories', {
          sessionId,
        });
        // dispatch('bindPollAnswers', {
        //   sessionId,
        // });
        dispatch('viewer/bindSessionViewers', { sessionId }, { root: true });
        dispatch('questions/bindQuestions', { sessionId }, { root: true });
        dispatch(
          'questions/bindQuestionColumns',
          { sessionId },
          { root: true }
        );
        dispatch('bindPresenterWordclouds', { sessionId });
      }
    },
    bindCurrentSession: firestoreAction(
      async ({ bindFirestoreRef }, sessionId) => {
        const db = firebase.firestore();
        const ref = db.collection('sessions').doc(sessionId);
        await bindFirestoreRef('currentSession', ref, {
          wait: true,
        });
      }
    ),
    bindTVData: firestoreAction(async ({ bindFirestoreRef }, sessionId) => {
      const db = firebase.firestore();
      const ref = db
        .collection('sessions')
        .doc(sessionId)
        .collection('tv')
        .doc('data');
      await bindFirestoreRef('tvData', ref, {
        wait: true,
      });
    }),
    bindPollTVData: firestoreAction(
      async ({ bindFirestoreRef }, { sessionId, pollId }) => {
        const db = firebase.firestore();
        const ref = db
          .collection('sessions')
          .doc(sessionId)
          .collection('polls')
          .doc(pollId)
          .collection('tv')
          .doc('data');
        await bindFirestoreRef('pollTVData', ref, {
          wait: true,
        });
      }
    ),
    bindAllSessionsRef: firestoreAction(
      async ({ bindFirestoreRef }, userId) => {
        const db = firebase.firestore();
        const ref = db.collection('sessions').where('author', '==', userId);
        await bindFirestoreRef('sessions', ref, {
          wait: true,
        });
      }
    ),
    bindUnlimitedSessionsRef: firestoreAction(
      async ({ bindFirestoreRef }, userId) => {
        const db = firebase.firestore();
        const ref = db
          .collection('sessions')
          .where('author', '==', userId)
          .where('sessionUnlimited', '==', true)
          .orderBy('startAt', 'desc');
        await bindFirestoreRef('unlimitedSessions', ref, {
          wait: true,
        });
      }
    ),
    bindSessionsRef: firestoreAction(async ({ bindFirestoreRef }, userId) => {
      const db = firebase.firestore();
      const now = firebase.firestore.Timestamp.fromDate(new Date());
      const ref = db
        .collection('sessions')
        .where('author', '==', userId)
        .where('endAt', '>', now)
        .orderBy('endAt', 'desc')
        .orderBy('startAt', 'desc');
      await bindFirestoreRef('sessions', ref, {
        wait: true,
      });
    }),
    bindPastSessionsRef: firestoreAction(
      async ({ bindFirestoreRef }, userId) => {
        const db = firebase.firestore();
        const now = firebase.firestore.Timestamp.fromDate(new Date());
        const ref = db
          .collection('sessions')
          .where('author', '==', userId)
          .where('endAt', '<=', now)
          .orderBy('endAt', 'desc')
          .orderBy('startAt', 'desc');
        await bindFirestoreRef('sessions', ref, {
          wait: true,
        });
      }
    ),
    async cancelSession(_, sessionId) {
      // Stop current session
      const db = firebase.firestore();
      if (sessionId) {
        const sessionsCollection = db.collection('sessions');
        await sessionsCollection
          .doc(sessionId)
          .update({ endAt: new Date(), sessionUnlimited: false });
      }
      window.location.reload();
    },
    async removeSession({ rootGetters }, { sessionId, sessionPath }) {
      const db = firebase.firestore();
      const userId = rootGetters['auth/getUserId'];
      const presentersCollection = db.collection('presenters');
      const sessionsCollection = db.collection('sessions');

      await sessionsCollection.doc(sessionId).delete();
      await presentersCollection
        .doc(userId)
        .collection('sessions')
        .doc(sessionId)
        .delete();

      if (
        sessionPath !== '/presenter/home' &&
        sessionPath !== '/presenter/sessions/past'
      ) {
        router.push({ name: 'Presenter Home' });
      }
    },

    async pauseSession(_, { sessionId, state }) {
      // Stop current session
      const db = firebase.firestore();
      if (sessionId) {
        const sessionsCollection = db.collection('sessions');
        await sessionsCollection
          .doc(sessionId)
          .set({ isPaused: state }, { merge: true });
      }
    },
    async updateSessionLabel({ getters, commit }, { label, labelId }) {
      const sessionId = getters['getCurrentSession'].shareToken;
      if (sessionId) {
        const db = firebase.firestore();
        await db
          .collection('sessions')
          .doc(sessionId)
          .update({ [`labels.${labelId}`]: label });
        commit('REMOVE_SESSION_LABEL', labelId);
      }
    },
    async updateSessionDescription(
      { getters, commit },
      { description, descriptionId }
    ) {
      const sessionId = getters['getCurrentSession'].shareToken;
      if (sessionId) {
        const db = firebase.firestore();
        await db
          .collection('sessions')
          .doc(sessionId)
          .update({ [`descriptions.${descriptionId}`]: description });
        commit('REMOVE_SESSION_DESCRIPTION', descriptionId);
      }
    },
    async updateSessionButtonLabel(
      { getters, commit },
      { buttonLabel, buttonId }
    ) {
      const sessionId = getters['getCurrentSession'].shareToken;
      if (sessionId) {
        const db = firebase.firestore();
        await db
          .collection('sessions')
          .doc(sessionId)
          .update({ [`buttonLabels.${buttonId}`]: buttonLabel });
        commit('REMOVE_SESSION_BUTTON_LABEL', buttonId);
      }
    },
    async fetchSessionComments({ commit, state }, { shareToken, page = 0 }) {
      commit('SET_LOADING_STATE', true);

      // End of comment list is reached, return
      if (
        state.internal.commentRequest.batchCount &&
        state.internal.commentRequest.nextPageId === ''
      ) {
        return;
      }

      let fromPageId = '';
      let previousBatchCount = 0;
      if (page !== 0) {
        previousBatchCount = state.internal.commentRequest.batchCount;
        fromPageId = state.internal.commentRequest.nextPageId;
      }

      const url = `https://rl-eu.func.link/evaluateFeedback?sessionID=${shareToken}&fromPageID=${fromPageId}&previousBatchCount=${previousBatchCount}&onlyComments=true`;
      const res: Response = await fetch(url, {
        method: 'get',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      const resJSON = await res.json();

      if (resJSON.feedback) {
        if (page !== 0) {
          commit('COMBINE_SESSION_COMMENTS', resJSON.feedback);
        } else {
          commit('SET_SESSION_COMMENTS', resJSON.feedback);
        }

        commit('SET_COMMENT_REQUEST', {
          batchCount: resJSON['batchCount'],
          nextPageId: resJSON['nextPageId'],
        });
        commit('SET_LOADING_STATE', false);
      }
    },
    bindTimelineData: firestoreAction(
      async ({ bindFirestoreRef }, sessionId) => {
        const db = firebase.firestore();

        const ref = db
          .collection('sessions')
          .doc(sessionId)
          .collection('timeline');
        await bindFirestoreRef('timelineData', ref, {
          wait: true,
        });
      }
    ),
    /**************** Polls **********/
    bindPresenterPolls: firestoreAction(
      async ({ bindFirestoreRef }, { sessionId }) => {
        const db = firebase.firestore();
        const ref = db
          .collection('sessions')
          .doc(sessionId)
          .collection('polls')
          .orderBy('order', 'asc');
        await bindFirestoreRef('sessionPolls', ref, {
          wait: true,
        });
      }
    ),
    async fetchPollResults({ commit }, shareToken) {
      commit('SET_LOADING_STATE', true);
      const db = firebase.firestore();
      const polls = await db
        .collection('sessions')
        .doc(shareToken)
        .collection('polls')
        .get();

      let url = `https://rl-eu.func.link/evaluateAllPolls?sessionID=${shareToken}`;
      for (const poll of polls.docs) {
        if (!poll.id.includes('progress') && !poll.id.includes('plz')) {
          url += `&pollsIDs=${poll.id}`;
        }
      }

      const res: Response = await fetch(url, {
        method: 'get',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      const resJSON = await res.json();

      if (resJSON.POLLS) {
        commit('SET_POLL_ANSWERS', resJSON.POLLS);
        commit('SET_LOADING_STATE', false);
      }
    },
    async fetchPollComments(
      { commit, state },
      { shareToken, pollId, page = 0 }
    ) {
      commit('SET_LOADING_STATE', true);

      // No poll id, return
      if (!pollId) {
        return;
      }

      // End of comment list is reached, return
      if (
        state.internal.commentRequest.batchCount &&
        state.internal.commentRequest.nextPageId === ''
      ) {
        return;
      }

      let previousBatchCount = 0;
      let fromPageId = '';
      if (page !== 0) {
        previousBatchCount = state.internal.commentRequest.batchCount;
        fromPageId = state.internal.commentRequest.nextPageId;
      }

      const url = `https://rl-eu.func.link/evaluateCommentsByPollID?sessionID=${shareToken}&pollID=${pollId}&fromPageID=${fromPageId}&previousBatchCount=${previousBatchCount}`;
      const res: Response = await fetch(url, {
        method: 'get',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      const resJSON = await res.json();

      if (resJSON.feedback) {
        if (page !== 0) {
          commit('COMBINE_POLL_COMMENTS', resJSON.feedback);
        } else {
          commit('SET_POLL_COMMENTS', resJSON.feedback);
        }

        commit('SET_COMMENT_REQUEST', {
          batchCount: resJSON['batchCount'],
          nextPageId: resJSON['nextPageId'],
        });
        commit('SET_LOADING_STATE', false);
      }
    },
    bindSelectedPollComments: firestoreAction(
      async ({ bindFirestoreRef }, { sessionId, pollId }) => {
        const db = firebase.firestore();
        const ref = db
          .collection('sessions')
          .doc(sessionId)
          .collection('polls')
          .doc(pollId)
          .collection('selectedComments')
          .orderBy('order', 'asc');
        await bindFirestoreRef('selectedPollComments', ref, {
          wait: true,
        });
      }
    ),
    async addCommentToSelection({ getters }, { pollId, comment }) {
      const sessionId = getters['getCurrentSession'].shareToken;
      const db = firebase.firestore();

      const _comment = { ...comment, createdAt: new Date(), order: -1 };

      await db
        .collection('sessions')
        .doc(sessionId)
        .collection('polls')
        .doc(pollId)
        .collection('selectedComments')
        .add(_comment);
    },
    async updateSelectedCommentsOrder({ getters }, { sortedComments, pollId }) {
      const sessionId = getters['getCurrentSession'].shareToken;

      if (sessionId && sortedComments) {
        const db = firebase.firestore();
        await sortedComments.map(async (c: any, index: number) => {
          await db
            .collection('sessions')
            .doc(sessionId)
            .collection('polls')
            .doc(pollId)
            .collection('selectedComments')
            .doc(c.id)
            .set(
              {
                order: index + 1,
              },
              { merge: true }
            );
        });
      }
    },
    async removeCommentFromSelection({ getters }, { pollId, commentId }) {
      const sessionId = getters['getCurrentSession'].shareToken;
      const db = firebase.firestore();

      await db
        .collection('sessions')
        .doc(sessionId)
        .collection('polls')
        .doc(pollId)
        .collection('selectedComments')
        .doc(commentId)
        .delete();
    },
    bindPollCategories: firestoreAction(
      async ({ bindFirestoreRef }, { sessionId }) => {
        const db = firebase.firestore();
        const ref = db
          .collection('sessions')
          .doc(sessionId)
          .collection('pollCategories');
        await bindFirestoreRef('pollCategories', ref, {
          wait: true,
        });
      }
    ),
    // bindPollAnswers: firestoreAction(
    //   async ({ bindFirestoreRef }, { sessionId }) => {
    //     const db = firebase.firestore();
    //     const ref = db
    //       .collection('sessions')
    //       .doc(sessionId)
    //       .collection('answers');
    //     await bindFirestoreRef('pollAnswers', ref, {
    //       wait: true,
    //     });
    //   }
    // ),
    async createPoll({ rootGetters, getters }, { form }) {
      const {
        title,
        answerOptions,
        type,
        description,
        choices,
        visible,
        isPaused,
        subType,
        screenBackground,
      } = form;

      const db = firebase.firestore();
      const sessionId = getters['getCurrentSession'].shareToken;
      const { uid } = rootGetters['auth/getUser'].data;
      const countPolls = getters['getSessionPolls'].length;

      const showTVControls = rootGetters['auth/getUserShowTVControls'];

      let newPoll;
      if (type === 'SLIDER') {
        newPoll = PresenterPoll.fromDict({
          title,
          type,
          answerOptions: {
            decimalPlaces: 2,
            decimalSeparator: ',',
            unit: '',
            min: parseInt(answerOptions.min),
            max: parseInt(answerOptions.max),
            step: parseInt(answerOptions.step),
            defaultValue: parseInt(answerOptions.defaultValue),
          },
          author: uid,
          visible,
          isPaused,
        });
      } else {
        newPoll = PresenterPoll.fromDict({
          title,
          type,
          answerOptions: {
            choices,
            choiceAmount: 1,
            showResultsBadgeBeforeSubmit: showTVControls ? false : true,
            showResultsAfterBeforeSubmit: showTVControls ? false : true,
            showResultsAsBarGraph: false,
            submitRequiresConfirmation: showTVControls ? false : true,
          },
          author: uid,
          visible,
          isPaused,
          order: countPolls + 1,
        });
      }

      if (subType) {
        newPoll.answerOptions.subType = subType;
      }

      if (description !== '') {
        newPoll.description = description;
      }

      newPoll.disableComments = true;

      if (screenBackground.default !== '') {
        newPoll.screenBackground = screenBackground;
      }

      if (answerOptions.timerDuration) {
        newPoll.answerOptions.timerDuration = parseFloat(
          answerOptions.timerDuration
        );
      }

      await db
        .collection('sessions')
        .doc(sessionId)
        .collection('polls')
        .add(newPoll.toJSON());
    },
    async updatePoll({ rootGetters, getters }, { form, pollId }) {
      const {
        title,
        answerOptions,
        type,
        description,
        choices,
        visible,
        isPaused,
        subType,
        screenBackground,
        order,
        disableComments,
      } = form;

      const { uid } = rootGetters['auth/getUser'].data;
      const showTVControls = rootGetters['auth/getUserShowTVControls'];

      let updatedPoll;
      if (type === 'SLIDER') {
        updatedPoll = PresenterPoll.fromDict({
          title,
          type,
          answerOptions: {
            decimalPlaces: 2,
            decimalSeparator: ',',
            unit: '',
            min: parseInt(answerOptions.min),
            max: parseInt(answerOptions.max),
            step: parseInt(answerOptions.step),
            defaultValue: parseInt(answerOptions.defaultValue),
          },
          author: uid,
          visible,
          isPaused,
          order,
        });
      } else {
        updatedPoll = PresenterPoll.fromDict({
          title,
          type,
          answerOptions: {
            choices,
            choiceAmount: 1,
            showResultsBadgeBeforeSubmit: showTVControls ? false : true,
            showResultsBadgeAfterSubmit: showTVControls ? false : true,
            submitRequiresConfirmation: showTVControls ? false : true,
          },
          author: uid,
          visible,
          isPaused,
          order,
        });
      }

      if (subType) {
        updatedPoll.answerOptions.subType = subType;
      }

      if (description !== '') {
        updatedPoll.description = { text: description };
      }

      if (answerOptions.timerDuration) {
        updatedPoll.answerOptions.timerDuration = parseFloat(
          answerOptions.timerDuration
        );
      }

      updatedPoll.disableComments = disableComments;

      if (screenBackground.default !== '') {
        updatedPoll.screenBackground = screenBackground;
      }

      const db = firebase.firestore();
      const sessionId = getters['getCurrentSession'].shareToken;

      if (sessionId && pollId) {
        db.collection('sessions')
          .doc(sessionId)
          .collection('polls')
          .doc(pollId)
          .update(updatedPoll.toJSON());

        // const categoryResults = await db
        //   .collection('sessions')
        //   .doc(sessionId)
        //   .collection('pollCategories')
        //   .where('polls', 'array-contains', pollId)
        //   .get();
        // categoryResults.docs.map(async (c) => {
        //   c.ref.update({
        //     polls: c.data().polls.filter((id: string) => id !== pollId),
        //   });
        // });

        // pollCategories.map(async (c: string) => {
        //   await db
        //     .collection('sessions')
        //     .doc(sessionId)
        //     .collection('pollCategories')
        //     .doc(c)
        //     .update({
        //       polls: firebase.firestore.FieldValue.arrayUnion(pollId),
        //     });
        // });
      }
    },
    async resetPoll({ getters, dispatch }, { pollId }) {
      const sessionId = getters['getCurrentSession'].shareToken;
      if (sessionId) {
        const db = firebase.firestore();
        // Remove all poll answers
        const pollAnswersDocs = await db
          .collection('sessions')
          .doc(sessionId)
          .collection('polls')
          .doc(pollId)
          .collection('answers')
          .get();
        pollAnswersDocs.docs.map(async (pollAnswerDoc) => {
          await pollAnswerDoc.ref.delete();
        });

        // Clean answers
        const answersDocs = await db
          .collection('sessions')
          .doc(sessionId)
          .collection('answers')
          .get();

        answersDocs.docs.map(async (answerDoc) => {
          if (answerDoc.data()[pollId]) {
            await answerDoc.ref.update({
              [pollId]: firebase.firestore.FieldValue.delete(),
            });
          }
        });

        // Clean evaluations
        const evaluationsDocs = await db
          .collection('sessions')
          .doc(sessionId)
          .collection('polls')
          .doc(pollId)
          .collection('evaluations')
          .get();

        evaluationsDocs.docs.map(async (evaluationDoc) => {
          await evaluationDoc.ref.delete();
        });

        // Reset timer and evaluations
        await db
          .collection('sessions')
          .doc(sessionId)
          .collection('polls')
          .doc(pollId)
          .set(
            {
              answerOptions: {
                timerStartDate: firebase.firestore.FieldValue.delete(),
                showResultsAsBarGraph: false,
              },
              updatedAt: new Date(),
              visible: false,
            },
            { merge: true }
          );

        // dispatch('presenter/fetchPollResults', sessionId);
        location.reload();
      }
    },
    async removePoll({ getters }, { pollId }) {
      const sessionId = getters['getCurrentSession'].shareToken;
      if (sessionId) {
        // Clean poll && poll answers
        const db = firebase.firestore();
        const pollAnswers = await db
          .collection('sessions')
          .doc(sessionId)
          .collection('polls')
          .doc(pollId)
          .collection('answers')
          .get();

        pollAnswers.docs.map(async (answer) => {
          answer.ref.delete();
        });

        await db
          .collection('sessions')
          .doc(sessionId)
          .collection('polls')
          .doc(pollId)
          .delete();

        // Clean answers
        const answersDocs = await db
          .collection('sessions')
          .doc(sessionId)
          .collection('answers')
          .get();

        answersDocs.docs.map(async (answerDoc) => {
          if (answerDoc.data()[pollId]) {
            await answerDoc.ref.update({
              [pollId]: firebase.firestore.FieldValue.delete(),
            });
          }
        });

        // Clean evaluations
        const evaluationsDocs = await db
          .collection('sessions')
          .doc(sessionId)
          .collection('polls')
          .doc(pollId)
          .collection('evaluations')
          .get();

        evaluationsDocs.docs.map(async (evaluationDoc) => {
          await evaluationDoc.ref.delete();
        });
      }
    },
    async togglePollVisibility({ getters }, { pollId, state }) {
      const sessionId = getters['getCurrentSession'].shareToken;
      if (sessionId) {
        const db = firebase.firestore();

        await db
          .collection('sessions')
          .doc(sessionId)
          .collection('polls')
          .doc(pollId)
          .update({ visible: !!state });
      }
    },
    async togglePollGroupVisibility({ getters }, { group, state }) {
      const sessionId = getters['getCurrentSession'].shareToken;
      if (sessionId) {
        const db = firebase.firestore();

        const querySnapshot = await db
          .collection('sessions')
          .doc(sessionId)
          .collection('polls')
          .where('group', '==', group)
          .get();

        querySnapshot.docs.map(
          async (snap) => await snap.ref.update({ visible: !!state })
        );
      }
    },
    async togglePollAnimation({ getters }, { pollId }) {
      const sessionId = getters['getCurrentSession'].shareToken;
      if (sessionId) {
        const db = firebase.firestore();
        await db
          .collection('sessions')
          .doc(sessionId)
          .collection('polls')
          .doc(pollId)
          .collection('tv')
          .doc('data')
          .set({ runAnimation: Date.now().toString() }, { merge: true });
      }
    },
    async togglePollResults({ getters }, { pollId, state }) {
      const sessionId = getters['getCurrentSession'].shareToken;
      if (sessionId) {
        const db = firebase.firestore();
        await db
          .collection('sessions')
          .doc(sessionId)
          .collection('polls')
          .doc(pollId)
          .set(
            {
              answerOptions: {
                showResultsAsBarGraph: !!state,
                timerStartDate: firebase.firestore.FieldValue.delete(),
              },
            },
            { merge: true }
          );
      }
    },
    async togglePollTimer({ getters }, { pollId }) {
      const sessionId = getters['getCurrentSession'].shareToken;
      if (sessionId) {
        const db = firebase.firestore();
        await db
          .collection('sessions')
          .doc(sessionId)
          .collection('polls')
          .doc(pollId)
          .set(
            {
              answerOptions: {
                timerStartDate: new Date(),
              },
            },
            { merge: true }
          );
      }
    },
    async resetPollAnimation({ getters }, { pollId }) {
      const sessionId = getters['getCurrentSession'].shareToken;
      if (sessionId) {
        const db = firebase.firestore();
        await db
          .collection('sessions')
          .doc(sessionId)
          .collection('polls')
          .doc(pollId)
          .collection('tv')
          .doc('data')
          .set({ resetAnimation: Date.now().toString() }, { merge: true });
      }
    },
    async togglePollStatisticsVisibility({ getters }, { pollId, state }) {
      const sessionId = getters['getCurrentSession'].shareToken;
      if (sessionId) {
        const db = firebase.firestore();
        await db
          .collection('sessions')
          .doc(sessionId)
          .collection('polls')
          .doc(pollId)
          .update({ showPollStatistics: !!state });
      }
    },
    async togglePollPaused({ getters }, { pollId, state }) {
      const sessionId = getters['getCurrentSession'].shareToken;
      if (sessionId) {
        const db = firebase.firestore();
        await db
          .collection('sessions')
          .doc(sessionId)
          .collection('polls')
          .doc(pollId)
          .update({ isPaused: !!state });
      }
    },
    async updatePollPosition({ getters }, newPollList) {
      const sessionId = getters['getCurrentSession'].shareToken;
      if (sessionId) {
        const db = firebase.firestore();

        const count = newPollList.length;
        await newPollList.map(async (poll: PresenterPoll, i: number) => {
          await db
            .collection('sessions')
            .doc(sessionId)
            .collection('polls')
            .doc(poll.id)
            .update({ position: count - i });
        });
      }
    },
    async togglePollLabelState({ getters }, state) {
      const sessionId = getters['getCurrentSession'].shareToken;
      if (sessionId) {
        const db = firebase.firestore();
        await db
          .collection('sessions')
          .doc(sessionId)
          .update({ hidePollLabels: state });
      }
    },
    async updatePollVoteResultType({ getters }, id) {
      const sessionId = getters['getCurrentSession'].shareToken;
      if (sessionId) {
        const db = firebase.firestore();
        await db
          .collection('sessions')
          .doc(sessionId)
          .update({ voteResultType: id });
      }
    },
    async fetchGroupPollResults({ commit }, { sessionId, polls }) {
      commit('SET_LOADING_STATE', true);
      const url = `https://rl-eu.func.link/evaluatePollsOpinionFlow?sessionID=${sessionId}&fromPollsIDs=${polls[0].id}&toPollsIDs=${polls[1].id}`;

      const res: Response = await fetch(url, {
        method: 'get',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      const resJSON = await res.json();

      if (resJSON) {
        commit('SET_POLL_GROUP_RESULT', resJSON);
        commit('SET_LOADING_STATE', false);
      }
    },
    /**************** Wordclouds **********/
    bindPresenterWordclouds: firestoreAction(
      async ({ bindFirestoreRef }, { sessionId }) => {
        const db = firebase.firestore();
        const ref = db
          .collection('sessions')
          .doc(sessionId)
          .collection('wordclouds');
        await bindFirestoreRef('sessionWordclouds', ref, {
          wait: true,
        });
      }
    ),
    async toggleWordcloudVisibility({ getters }, { state }) {
      const sessionId = getters['getCurrentSession'].shareToken;
      if (sessionId) {
        const db = firebase.firestore();
        await db
          .collection('sessions')
          .doc(sessionId)
          .update({ isWordcloudActive: !!state });
      }
    },
    async toggleWordcloudPaused({ getters }, { state }) {
      const sessionId = getters['getCurrentSession'].shareToken;
      if (sessionId) {
        const db = firebase.firestore();
        await db
          .collection('sessions')
          .doc(sessionId)
          .update({ isWordcloudPaused: !!state });
      }
    },
    async resetWordcloud({ getters }) {
      const sessionId = getters['getCurrentSession'].shareToken;
      if (sessionId) {
        const db = firebase.firestore();
        const newWordcloudId = await db
          .collection('sessions')
          .doc(sessionId)
          .collection('wordclouds')
          .add({});
        if (newWordcloudId.id) {
          await db
            .collection('sessions')
            .doc(sessionId)
            .update({ activeWordcloud: newWordcloudId.id });
        }
      }
    },
    async updateWordcloudTopic({ getters }, { topic }) {
      const sessionId = getters['getCurrentSession'].shareToken;
      if (sessionId) {
        const db = firebase.firestore();
        await db
          .collection('sessions')
          .doc(sessionId)
          .update({ wordcloudTopic: topic });
      }
    },
    /**************** TV Mode **********/
    async fetchTVData({ dispatch }, shareToken) {
      const db = firebase.firestore();
      const tvDataRes = await db
        .collection('sessions')
        .doc(shareToken)
        .collection('tv')
        .doc('data')
        .get();

      if (tvDataRes && tvDataRes.data()) {
        dispatch('bindTVData', shareToken);
      }
    },
    async activateTVMode({ getters }, { pollId, state, pollResults, groupId }) {
      const sessionId = getters['getCurrentSession'].shareToken;

      if (sessionId && pollId) {
        const db = firebase.firestore();
        if (state) {
          // Activate tv poll
          await db
            .collection('sessions')
            .doc(sessionId)
            .collection('tv')
            .doc('data')
            .set(
              {
                activeTVPolls: firebase.firestore.FieldValue.arrayUnion(pollId),
              },
              { merge: true }
            );

          const mode = groupId ? groupId : `poll+${pollId}`;

          await db
            .collection('sessions')
            .doc(sessionId)
            .collection('polls')
            .doc(pollId)
            .collection('tv')
            .doc('data')
            .set({ mode }, { merge: true });
        } else {
          // Disable tv poll
          await db
            .collection('sessions')
            .doc(sessionId)
            .collection('tv')
            .doc('data')
            .set(
              {
                activeTVPolls: firebase.firestore.FieldValue.arrayRemove(
                  pollId
                ),
              },
              { merge: true }
            );
          await db
            .collection('sessions')
            .doc(sessionId)
            .collection('polls')
            .doc(pollId)
            .collection('tv')
            .doc('data')
            .set({ mode: '' }, { merge: true });
        }
        if (pollResults) {
          await db
            .collection('sessions')
            .doc(sessionId)
            .collection('polls')
            .doc(pollId)
            .collection('tv')
            .doc('data')
            .update({
              poll: pollResults.poll,
              results: pollResults.results,
              resetAnimation: Date.now().toString(),
            });
        }
      }
    },
    async activateCommentTVMode({ getters }, { pollId, state, comment }) {
      const sessionId = getters['getCurrentSession'].shareToken;

      if (sessionId && pollId) {
        const db = firebase.firestore();

        if (comment) {
          await db
            .collection('sessions')
            .doc(sessionId)
            .collection('tv')
            .doc('data')
            .update({
              comment: state
                ? { ...comment }
                : { title: '', text: '', type: '' },
              selectedPollId: state ? pollId : '',
            });

          await db
            .collection('sessions')
            .doc(sessionId)
            .collection('polls')
            .doc(pollId)
            .collection('tv')
            .doc('data')
            .update({
              comment: state
                ? { ...comment }
                : { title: '', text: '', type: '' },
            });
        }
      }
    },
    async syncPollResults({ getters }, { tvResults, pollId }) {
      const sessionId = getters['getCurrentSession'].shareToken;
      if (sessionId && pollId) {
        const db = firebase.firestore();
        if (tvResults) {
          await db
            .collection('sessions')
            .doc(sessionId)
            .collection('polls')
            .doc(pollId)
            .collection('tv')
            .doc('data')
            .update({ ...tvResults });
        }
      }
    },
    /**************** Session Themes Section **********/
    bindSessionThemes: firestoreAction(async ({ bindFirestoreRef }) => {
      const db = firebase.firestore();
      const ref = db.collection('themes');
      await bindFirestoreRef('sessionThemes', ref, {
        wait: true,
      });
    }),
  },
};

export { PresenterModule };
