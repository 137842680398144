class ThemeModel implements ThemeDTO {
  constructor(
    public name: string,
    public description: string,
    public primaryColor: string,
    public secondaryColor: string,
    public reactionColor: string,
    public textColor: string,
    public buttonTextColor: string,
    public buttonColor: string,
    public headlineColor: string,
    public thumbnailURL: string,
    public tvWallpaperURL: string,
    public isGreenscreenFeatureEnabled: boolean,
    public hideTimeLabel: boolean,
    public isWhitelabel: boolean,
    public utmName: string,
    public limitReactions: boolean,
    public reactions: string[]
  ) {}

  static create(dto: ThemeDTO): ThemeModel {
    return new ThemeModel(
      dto.name,
      dto.description,
      dto.primaryColor,
      dto.secondaryColor,
      dto.reactionColor,
      dto.textColor,
      dto.buttonTextColor,
      dto.buttonTextColor,
      dto.headlineColor,
      dto.thumbnailURL,
      dto.tvWallpaperURL,
      dto.isGreenscreenFeatureEnabled,
      dto.hideTimeLabel,
      dto.isWhitelabel,
      dto.utmName,
      dto.limitReactions,
      dto.reactions
    );
  }

  static defaultTheme(): ThemeModel {
    return new ThemeModel(
      'reaction.link',
      '',
      '#0183FE',
      '#67EDB8',
      '#0183FE',
      '#989EB2',
      '#ffffff',
      '#0183FE',
      '#4B5362',
      'https://firebasestorage.googleapis.com/v0/b/reaction-link-dev.appspot.com/o/themes%2Freaction.link%2Freaction-link-logo-web.png?alt=media&token=4a06727e-da3c-48ce-adf6-f03f913b1972',
      'https://firebasestorage.googleapis.com/v0/b/reaction-link-dev.appspot.com/o/themes%2Freaction.link%2Freaction-link-logo-web.png?alt=media&token=4a06727e-da3c-48ce-adf6-f03f913b1972',
      false,
      false,
      false,
      'reaction.link',
      false,
      []
    );
  }

  static fromDict(dict: any): ThemeModel {
    let {
      name,
      description,
      primaryColor,
      secondaryColor,
      reactionColor,
      textColor,
      buttonTextColor,
      buttonColor,
      headlineColor,
      thumbnailURL,
      tvWallpaperURL,
      isGreenscreenFeatureEnabled,
      hideTimeLabel,
      isWhitelabel,
      utmName,
      limitReactions,
      reactions,
    } = dict;

    name = name ?? '';
    description = description ?? '';
    primaryColor = primaryColor ?? '';
    secondaryColor = secondaryColor ?? '';
    reactionColor = reactionColor ?? '';
    textColor = textColor ?? '';
    buttonTextColor = buttonTextColor ?? '';
    buttonColor = buttonColor ?? '';
    headlineColor = headlineColor ?? '';
    thumbnailURL = thumbnailURL ?? '';
    tvWallpaperURL = tvWallpaperURL ?? '';
    isGreenscreenFeatureEnabled = isGreenscreenFeatureEnabled || false;
    hideTimeLabel = hideTimeLabel || false;
    isWhitelabel = isWhitelabel || false;
    utmName = utmName || 'reaction.link';
    limitReactions = limitReactions || false;
    reactions = reactions || [];

    return new ThemeModel(
      name,
      description,
      primaryColor,
      secondaryColor,
      reactionColor,
      textColor,
      buttonTextColor,
      buttonColor,
      headlineColor,
      thumbnailURL,
      tvWallpaperURL,
      isGreenscreenFeatureEnabled,
      hideTimeLabel,
      isWhitelabel,
      utmName,
      limitReactions,
      reactions
    );
  }
}

interface ThemeDTO {
  name: string;
  description: string;
  primaryColor: string;
  secondaryColor: string;
  reactionColor: string;
  textColor: string;
  buttonTextColor: string;
  buttonColor: string;
  headlineColor: string;
  thumbnailURL: string;
  tvWallpaperURL: string;
  isGreenscreenFeatureEnabled: boolean;
  hideTimeLabel: boolean;
  isWhitelabel: boolean;
  utmName: string;
  limitReactions: boolean;
  reactions: string[];
}

export default ThemeModel;
