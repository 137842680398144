import firebase from 'firebase';
import { Module } from 'vuex';
import { firestoreAction } from 'vuexfire';

// TODO: Refactor preferences to unifying system

interface MailPreferences {
  sessionReportOptIn: boolean;
  weeklyReportOptIn: boolean;
  marketingOptIn: boolean;
}

export interface PreferencesState {
  preferences: MailPreferences[];
}

const PreferencesModule: Module<PreferencesState, any> = {
  namespaced: true,
  state: {
    preferences: [
      {
        sessionReportOptIn: false,
        weeklyReportOptIn: false,
        marketingOptIn: false,
      },
    ],
  } as PreferencesState,
  getters: {
    getUserPreferences: (state) => state.preferences,
  },
  mutations: {
    RESET(state) {
      state.preferences = [
        {
          sessionReportOptIn: false,
          weeklyReportOptIn: false,
          marketingOptIn: false,
        },
      ];
    },
  },
  actions: {
    bindUserPreferences: firestoreAction(
      async ({ bindFirestoreRef }, userId: string) => {
        const db = firebase.firestore();
        const ref = db
          .collection('presenters')
          .doc(userId)
          .collection('preferences');
        await bindFirestoreRef('preferences', ref, {
          wait: true,
        });
      }
    ),
    async updateMailPreference(_, { userId, key, value }) {
      const db = firebase.firestore();
      await db
        .collection('presenters')
        .doc(userId)
        .collection('preferences')
        .doc('mailPreferences')
        .set({ [key]: value }, { merge: true });
    },
  },
};

export { PreferencesModule };
