import { i18n } from '@/lang/i18n.config';
import { ReactionDTO } from '../dtos/reaction';

export const formatDateTime = (dateRaw: any): string => {
  const date = typeof dateRaw === 'object' ? dateRaw : dateRaw.toDate();
  const dateTimeFormat = new Intl.DateTimeFormat(
    i18n.t('utils.toolsLang') as string,
    {
      year: 'numeric',
      month: 'short',
      day: '2-digit',
      hour: 'numeric',
      minute: 'numeric',
    }
  );
  const [
    { value: month },
    ,
    { value: day },
    ,
    { value: year },
    ,
    { value: hour },
    ,
    { value: minutes },
  ] = dateTimeFormat.formatToParts(date);
  const timePeriod = hour > '11' ? 'pm' : 'am';
  return i18n.t('utils.toolsAtFormatted', {
    month: month,
    day: day,
    year: year,
    hour: hour,
    minutes: minutes,
    timePeriod: timePeriod,
  }) as string;
};

export const formatDate = (dateRaw: any): string => {
  const date = typeof dateRaw === 'object' ? dateRaw : dateRaw.toDate();
  const dateTimeFormat = new Intl.DateTimeFormat(
    i18n.t('utils.toolsLang') as string,
    {
      weekday: 'long',
      month: 'long',
      day: '2-digit',
      year: 'numeric',
    }
  );
  const [
    { value: weekday },
    ,
    { value: month },
    ,
    { value: day },
    ,
    { value: year },
  ] = dateTimeFormat.formatToParts(date);
  return ` ${weekday}, ${month} ${day}, ${year}`;
};

export const millisToTimeString = (millis: number): string => {
  const minutes = Math.floor((millis / (1000 * 60)) % 60),
    hours = Math.floor(millis / (1000 * 60 * 60));

  const hoursStr = hours < 10 ? `0${hours}` : hours;
  const minutesStr = minutes < 10 ? `0${minutes}` : minutes;

  return `${hoursStr}:${minutesStr}`;
};

export const formatDateString = (date: Date | null): string => {
  const dateToConvert = date !== null ? date : new Date();
  const dd = String(dateToConvert.getDate()).padStart(2, '0');
  const mm = String(dateToConvert.getMonth() + 1).padStart(2, '0'); //January is 0!
  const yyyy = dateToConvert.getFullYear();
  return `${yyyy}-${mm}-${dd}`;
};

export const millisToMinutes = (millis: number): number => {
  const minutes = Math.floor(millis / 60000);
  return minutes;
};

export const incrementReactionCount = (
  reactions: ReactionDTO,
  reactionName: string
): ReactionDTO => {
  switch (reactionName) {
    case 'thumbs-up': {
      reactions.thumbsUpCount = reactions.thumbsUpCount + 1;
      break;
    }
    case 'thumbs-down': {
      reactions.thumbsDownCount = reactions.thumbsDownCount + 1;
      break;
    }
    case 'love': {
      reactions.heartCount = reactions.heartCount + 1;
      break;
    }
    case 'funny': {
      reactions.funEmojiCount = reactions.funEmojiCount + 1;
      break;
    }
    case 'sad': {
      reactions.sadEmojiCount = reactions.sadEmojiCount + 1;
      break;
    }
    case 'claps': {
      reactions.clapsCount = reactions.clapsCount + 1;
      break;
    }
    case 'rocket': {
      reactions.rocketCount = reactions.rocketCount + 1;
      break;
    }
    default:
      break;
  }
  return reactions;
};
