import Vue from 'vue';
import { ActionContext, Module } from 'vuex';
import { firestoreAction } from 'vuexfire';
import { ViewerQuestion } from '@/api/interfaces.api';
import firebase from 'firebase';
import QuestionColumn from '@/dtos/questionColumn';

export interface QuestionState {
  columns: QuestionColumn[];
  questions: ViewerQuestion[];
  questionAnswers: { [id: string]: string };
}

const sessionInterceptor = async (
  actionContext: ActionContext<QuestionState, any>,
  args: any,
  callback: (
    db: firebase.firestore.Firestore,
    sessionId: string,
    args: any,
    actionContext: ActionContext<QuestionState, any>
  ) => Promise<any>
): Promise<void> => {
  const { rootGetters } = actionContext;
  const sessionId = rootGetters['presenter/getCurrentSession'].id;
  if (sessionId) {
    const db = firebase.firestore();
    await callback(db, sessionId, args, actionContext);
  }
};

const QuestionModule: Module<QuestionState, any> = {
  namespaced: true,
  state: {
    columns: [],
    questions: [],
    questionAnswers: {},
  } as QuestionState,
  getters: {
    getBoardColumns: (state) => state.columns,
    getColumnQuestions: (state) => (columnId: string) => {
      return state.questions.filter((q) => columnId === q.columnId);
    },
    getAllQuestions: (state) => state.questions,
    getVisibleQuestions: (state) => state.questions.filter((q) => q.isVisible),
    getHiddenQuestions: (state) => state.questions.filter((q) => !q.isVisible),
    getQuestionsByQuery: (state) => (query: string) => {
      switch (query) {
        case 'all':
          return state.questions.filter((q) => !q.isSelected);
        case 'liked':
          return state.questions
            .filter((q) => !q.isSelected)
            .sort((a, b) =>
              a.upvotes < b.upvotes ? 1 : b.upvotes > a.upvotes ? -1 : 0
            );
        case 'answered':
          return state.questions.filter((q) => !q.isSelected && q.isAnswered);
        case 'highlighted':
          return state.questions.filter((q) => !q.isSelected && q.isBookmarked);
        default:
          return [];
      }
    },
    getSelectedQuestions: (state) =>
      state.questions.filter((q) => q.isSelected),
    getAnswers: (state) => state.questionAnswers,
  },
  mutations: {
    ADD_QUESTION_ANSWER(state, { questionId, answer }) {
      Vue.set(state.questionAnswers, questionId, answer);
    },
    REMOVE_QUESTION_ANSWER(state, questionId) {
      delete state.questionAnswers[questionId];
    },
    RESET(state) {
      state.questions = [];
      state.columns = [];
      state.questionAnswers = {};
    },
  },
  actions: {
    bindQuestionColumns: firestoreAction(
      async ({ bindFirestoreRef }, { sessionId }) => {
        const db = firebase.firestore();
        const ref = db
          .collection('sessions')
          .doc(sessionId)
          .collection('questionColumns')
          .orderBy('position', 'asc');

        await bindFirestoreRef('columns', ref, {
          wait: true,
        });
      }
    ),
    async createColumn(actions, args) {
      await sessionInterceptor(
        actions,
        args,
        async (db, sessionId, { name, columnArr }) => {
          const count: number = columnArr.length;
          const ref = db
            .collection('sessions')
            .doc(sessionId)
            .collection('questionColumns');
          const newColumn = await ref.add({ name });
          await ref
            .doc(newColumn.id)
            .update({ id: newColumn.id, position: count + 1 });
        }
      );
    },
    async updateColumnName(actions, args) {
      await sessionInterceptor(
        actions,
        args,
        async (db, sessionId, { newColumnName, columnId }) => {
          await db
            .collection('sessions')
            .doc(sessionId)
            .collection('questionColumns')
            .doc(columnId)
            .update({ name: newColumnName });
        }
      );
    },
    async updateColumnOrder(actions, args) {
      await sessionInterceptor(
        actions,
        args,
        async (db, sessionId, newColumnArr) => {
          await newColumnArr.map(async (c: QuestionColumn, i: number) => {
            await db
              .collection('sessions')
              .doc(sessionId)
              .collection('questionColumns')
              .doc(c.id)
              .update({ position: i });
          });
        }
      );
    },
    async removeColumn(actions, args) {
      await sessionInterceptor(
        actions,
        args,
        async (db, sessionId, { columnId }, { state }) => {
          const questions = state.questions;

          if (columnId) {
            await db
              .collection('sessions')
              .doc(sessionId)
              .collection('questionColumns')
              .doc(columnId)
              .delete();

            // Iterate over questions
            questions.map(async (q) => {
              if (q.columnId === columnId) {
                await db
                  .collection('sessions')
                  .doc(sessionId)
                  .collection('questions')
                  .doc(q.id)
                  .delete();
              }
            });
          }
        }
      );
    },
    bindQuestions: firestoreAction(
      async ({ bindFirestoreRef }, { sessionId }) => {
        const db = firebase.firestore();
        const ref = db
          .collection('sessions')
          .doc(sessionId)
          .collection('questions')
          .orderBy('position', 'desc');

        await bindFirestoreRef('questions', ref, {
          wait: true,
        });
      }
    ),
    async toggleQuestionVisibility(actions, args) {
      await sessionInterceptor(
        actions,
        args,
        async (db, sessionId, { questionId, state }) => {
          await db
            .collection('sessions')
            .doc(sessionId)
            .collection('questions')
            .doc(questionId)
            .update({ isVisible: !!state });
        }
      );
    },
    async toggleQuestionAnswered(actions, args) {
      await sessionInterceptor(
        actions,
        args,
        async (db, sessionId, { questionId, state }) => {
          await db
            .collection('sessions')
            .doc(sessionId)
            .collection('questions')
            .doc(questionId)
            .update({ isAnswered: !!state });
        }
      );
    },
    async toggleQuestionBookmarked(actions, args) {
      await sessionInterceptor(
        actions,
        args,
        async (db, sessionId, { questionId, state }) => {
          await db
            .collection('sessions')
            .doc(sessionId)
            .collection('questions')
            .doc(questionId)
            .update({ isBookmarked: !!state });
        }
      );
    },
    async updateQuestionOrder(actions, args) {
      await sessionInterceptor(
        actions,
        args,
        async (db, sessionId, { newQuestionArr, columnId }) => {
          const count = newQuestionArr.length;
          await newQuestionArr.map(
            async (question: ViewerQuestion, i: number) => {
              await db
                .collection('sessions')
                .doc(sessionId)
                .collection('questions')
                .doc(question.id)
                .update({ position: count - i, columnId });
            }
          );
        }
      );
    },
    async updateQuestionAnswer(actions, args) {
      await sessionInterceptor(
        actions,
        args,
        async (db, sessionId, { questionId, questionAnswer }, { commit }) => {
          await db
            .collection('sessions')
            .doc(sessionId)
            .collection('questions')
            .doc(questionId)
            .update({ answer: questionAnswer });
          commit('REMOVE_QUESTION_ANSWER', questionId);
        }
      );
    },
    async removeQuestion(actions, args) {
      await sessionInterceptor(
        actions,
        args,
        async (db, sessionId, { questionId }) => {
          await db
            .collection('sessions')
            .doc(sessionId)
            .collection('questions')
            .doc(questionId)
            .delete();
        }
      );
    },
    async toggleQuestionsVisibilitySetting(actions, args) {
      await sessionInterceptor(actions, args, async (db, sessionId, state) => {
        await db
          .collection('sessions')
          .doc(sessionId)
          .update({ hideAllIncomingQuestions: !!state });
      });
    },
    async toggleQuestionsShowUserNameInputSetting(actions, args) {
      await sessionInterceptor(actions, args, async (db, sessionId, state) => {
        await db
          .collection('sessions')
          .doc(sessionId)
          .update({ showQuestionsUserNameInput: !!state });
      });
    },
    async toggleQuestionsLikesSetting(actions, args) {
      await sessionInterceptor(actions, args, async (db, sessionId, state) => {
        await db
          .collection('sessions')
          .doc(sessionId)
          .update({ hideQuestionLikes: state });
      });
    },
  },
};

export { QuestionModule };
