import { Module } from 'vuex';
import { EventBus } from '@/utils/eventbus';

export interface ErrorState {
  showError: boolean;
}

const ErrorHandlingModule: Module<ErrorState, any> = {
  namespaced: true,
  state: {
    showError: false,
  } as ErrorState,
  getters: {
    isErrorVisible(state) {
      return state.showError;
    },
  },
  mutations: {
    SET_ERROR(state, payload) {
      state.showError = payload;
    },
    RESET(state) {
      state.showError = false;
    },
  },
  actions: {
    handleError({ dispatch }, error: any) {
      // TODO: DEPRECATED
      EventBus.$emit('error', error.msg);
      dispatch('toggleLoadingIndicator', false, { root: true });
    },
    toggleError({ commit, dispatch }, errorState: boolean) {
      dispatch('toggleLoadingIndicator', false, { root: true });
      commit('SET_ERROR', errorState);
    },
  },
};

export { ErrorHandlingModule };
