import { Module } from 'vuex';
import Languages from '../../lang';

const supportedLanguages = Object.getOwnPropertyNames(Languages);

export interface LangState {
  language: string;
}

const LangModule: Module<LangState, any> = {
  namespaced: true,
  state: {
    language: localStorage.getItem('language') || '',
  } as LangState,
  getters: {
    getCurrentLang: (state) => state.language,
  },
  mutations: {
    SET_LANGUAGE(state, lang) {
      localStorage.setItem('language', lang);
      state.language = lang;
    },
  },
  actions: {
    setLanguage({ commit }, languages) {
      if (typeof languages === 'string') {
        commit('SET_LANGUAGE', languages);
      } else {
        const language = supportedLanguages.find((sl) =>
          languages.find((l: any) =>
            l.split(new RegExp(sl, 'gi')).length - 1 > 0 ? sl : null
          )
        );
        commit('SET_LANGUAGE', language);
      }
    },
  },
};

export { LangModule };
