import { Module } from 'vuex';
import * as firebase from 'firebase/app';
import 'firebase/firestore';
import { router } from '@/router';
import { firestoreAction } from 'vuexfire';
import { PresenterPoll, ViewerQuestion } from '@/api/interfaces.api';
import { incrementReactionCount } from '@/utils/tools';
import ThemeModel from '@/dtos/theme';
import PresenterSession from '@/dtos/session';

export interface ViewerState {
  isSessionValid: boolean;
  currentSession?: PresenterSession;
  currentSessionId?: string;
  sessionViewers?: any;
  sessionQuestions: ViewerQuestion[];
  sessionPolls: PresenterPoll[];
  sessionWordclouds: any[];
  sessionTheme: ThemeModel;
}

const ViewerModule: Module<ViewerState, any> = {
  namespaced: true,
  state: {
    isSessionValid: false,
    currentSession: undefined,
    currentSessionId: undefined,
    sessionViewers: undefined,
    sessionQuestions: [],
    sessionPolls: [],
    sessionWordclouds: [],
    sessionTheme: ThemeModel.defaultTheme(),
  } as ViewerState,
  getters: {
    isSessionValid(state) {
      return state.isSessionValid;
    },
    getActiveSession: (state) => state.currentSession || null,
    getSessionViewers: (state) => state.sessionViewers || null,
    getSessionQuestions: (state) => (uid: string) =>
      state.sessionQuestions.filter(
        (question) => question.isVisible || question.author === uid
      ),
    getSessionPolls: (state) => state.sessionPolls,
    getSessionWordclouds: (state) => state.sessionWordclouds,
    getSessionTheme: (state) => state.sessionTheme,
  },
  mutations: {
    SET_SESSION_VALID(state, payload) {
      state.isSessionValid = payload;
    },
    SET_CURRENT_SESSION_ID(state, { id }) {
      state.currentSessionId = id;
    },
    SET_SESSION_THEME(state, payload) {
      state.sessionTheme = payload;
    },
    RESET(state) {
      state.isSessionValid = false;
      state.currentSession = undefined;
      state.currentSessionId = undefined;
      state.sessionViewers = undefined;
      state.sessionQuestions = [];
      state.sessionPolls = [];
      state.sessionWordclouds = [];
      state.sessionTheme = ThemeModel.defaultTheme();
    },
  },
  actions: {
    async isSessionValid({ commit, dispatch }, { sessionToken, goToSession }) {
      if (sessionToken) {
        const db = firebase.firestore();
        const sessionCollection = db.collection('sessions');
        const data = await sessionCollection
          .where('shareToken', '==', sessionToken)
          .get();

        if (data) {
          commit('SET_SESSION_VALID', !!data.docs.length);
          if (data.docs.length) {
            commit('SET_CURRENT_SESSION_ID', {
              id: data.docs[0].id,
            });
            const sessionId = data.docs[0].id;
            await dispatch('bindSessionRef', { sessionId });
            if (goToSession)
              router.replace({
                name: 'Viewer Session',
                params: { sessionId: sessionToken },
              });
          }
        }
      }
    },
    async fetchSessionTheme({ commit }, { sessionUserGroup }) {
      const db = firebase.firestore();
      const themeRef = db
        .collection('themes')
        .doc(sessionUserGroup || 'reaction.link');
      themeRef
        .get()
        .then((doc) => {
          if (doc.exists) {
            const theme = ThemeModel.fromDict(doc.data());
            if (theme) {
              commit('SET_SESSION_THEME', theme);
            } else {
              console.log('No such document!');
            }
          }
        })
        .catch(function (error) {
          console.log('Error getting document:', error);
        });
    },
    bindSessionRef: firestoreAction(
      async ({ bindFirestoreRef }, { sessionId }) => {
        const db = firebase.firestore();
        const ref = db.collection('sessions').doc(sessionId);
        await bindFirestoreRef('currentSession', ref, {
          wait: true,
        });
      }
    ),
    bindSessionViewers: firestoreAction(
      async ({ bindFirestoreRef }, { sessionId }) => {
        const db = firebase.firestore();
        const ref = db
          .collection('sessions')
          .doc(sessionId)
          .collection('viewers');
        await bindFirestoreRef('sessionViewers', ref, {
          wait: true,
        });
      }
    ),
    submitReaction(
      { state, dispatch, rootGetters },
      { reactionName, currentMin }
    ) {
      // firebase.analytics().logEvent('reaction_submitted');
      const sessionId = state.currentSession ? state.currentSession.id : null;

      if (sessionId && reactionName) {
        const db = firebase.firestore();
        const sessionRef = db.collection('sessions').doc(sessionId);
        db.runTransaction((transaction) => {
          return transaction.get(sessionRef).then((doc: any) => {
            if (!doc.exists) {
              throw 'Document does not exist!';
            }
            let reactions = doc.data().reactions;
            reactions = incrementReactionCount(reactions, reactionName);

            transaction.update(sessionRef, { reactions });
          });
        });
        const viewerId = rootGetters['auth/getUserId'];
        if (viewerId) {
          const viewerRef = sessionRef.collection('viewers').doc(viewerId);
          db.runTransaction((transaction) => {
            return transaction.get(viewerRef).then((doc: any) => {
              let reactionsCount = 0;
              if (doc.exists) {
                reactionsCount = doc.data().reactionsCount;
              }
              reactionsCount += 1;

              transaction.set(viewerRef, { reactionsCount }, { merge: true });
            });
          });
        }
        if (currentMin) {
          dispatch('updateTimeline', { sessionId, currentMin, reactionName });
        }
      }
    },
    updateTimeline(_, { sessionId, currentMin, reactionName }) {
      const db = firebase.firestore();
      const timelineRef = db
        .collection('sessions')
        .doc(sessionId)
        .collection('timeline')
        .doc(String(currentMin));
      // TODO: Fix bug that occurred when reacting with claps too many times in a minutes (threshold around 10)
      db.runTransaction((transaction) => {
        return transaction.get(timelineRef).then((doc: any) => {
          let reactions = {
            thumbsUpCount: 0,
            thumbsDownCount: 0,
            heartCount: 0,
            funEmojiCount: 0,
            sadEmojiCount: 0,
            clapsCount: 0,
            rocketCount: 0,
          };
          if (doc.exists) {
            reactions = doc.data().reactions;
            reactions = incrementReactionCount(reactions, reactionName);
            transaction.update(timelineRef, { reactions });
          } else {
            reactions = incrementReactionCount(reactions, reactionName);
            transaction.set(timelineRef, { reactions }, { merge: true });
          }
        });
      });
    },
    async submitReview(_, { rating, message, sessionId }) {
      if (rating && message && sessionId) {
        const db = firebase.firestore();
        const sessionCollection = db.collection('sessions');
        try {
          await sessionCollection.doc(sessionId).collection('reviews').add({
            rating,
            message,
          });
        } catch (e) {
          console.log(e);
        }
      }
    },
    /********* Viewer Questions ************/
    bindSessionQuestions: firestoreAction(
      async ({ bindFirestoreRef }, { sessionId }) => {
        const db = firebase.firestore();
        const ref = db
          .collection('sessions')
          .doc(sessionId)
          .collection('questions')
          .orderBy('position', 'desc');
        await bindFirestoreRef('sessionQuestions', ref, {
          wait: true,
        });
      }
    ),
    async submitQuestion({ rootGetters, getters, state }, { title, name }) {
      const sessionId = state.currentSession ? state.currentSession.id : null;
      if (sessionId) {
        const db = firebase.firestore();
        const author = rootGetters['auth/getUserId'];
        const questionsRef = db
          .collection('sessions')
          .doc(sessionId)
          .collection('questions');
        const questionCountRef = db.collection('sessions').doc(sessionId);

        const sessionQuestions = getters['getSessionQuestions'](author);
        let lastQuestionPosition = 0;
        if (sessionQuestions.length > 0) {
          lastQuestionPosition = sessionQuestions[0].position;
        }

        if (name && name.replace(/\s/g, '').length) {
          localStorage.setItem('username', name);
        }

        const question = ViewerQuestion.fromDict({
          title,
          position: lastQuestionPosition + 1,
          initialPosition: 0,
          author,
          isVisible: false,
          questionerName:
            name && name.replace(/\s/g, '').length ? name : 'anonym',
        });

        const questionId = await questionsRef.add(question.toJSON());

        db.runTransaction((transaction) => {
          return transaction.get(questionCountRef).then((doc: any) => {
            if (!doc.exists) {
              throw 'Document does not exist!';
            }

            let questionsCount = 1;
            if (doc.data().questionsCount) {
              questionsCount = doc.data().questionsCount + 1;
            }

            questionsRef.doc(questionId.id).update({
              isVisible: !state.currentSession?.hideAllIncomingQuestions,
              initialPosition: isNaN(questionsCount) ? 1 : questionsCount,
            });

            transaction.update(questionCountRef, { questionsCount });
          });
        });

        router.push({ name: 'Viewer Session Questions' });
      }
    },
    async submitQuestionUpvote({ state }, questionId) {
      // firebase.analytics().logEvent('question_upvote');
      const sessionId = state.currentSession ? state.currentSession.id : null;

      if (sessionId && questionId) {
        const db = firebase.firestore();
        const questionRef = db
          .collection('sessions')
          .doc(sessionId)
          .collection('questions')
          .doc(questionId);
        db.runTransaction((transaction) => {
          return transaction.get(questionRef).then((doc: any) => {
            if (!doc.exists) {
              throw 'Document does not exist!';
            }
            const upvotes = doc.data().upvotes + 1;

            transaction.update(questionRef, { upvotes });
          });
        });
      }
    },
    /********* Polls ************/
    bindSessionPolls: firestoreAction(
      async ({ bindFirestoreRef }, { sessionId }) => {
        const db = firebase.firestore();
        const ref = db
          .collection('sessions')
          .doc(sessionId)
          .collection('polls')
          .where('isActive', '==', true)
          .orderBy('position', 'desc');
        await bindFirestoreRef('sessionPolls', ref, {
          wait: true,
        });
      }
    ),
    async submitPollAnswer({ state, rootGetters }, { choices, pollId }) {
      // firebase.analytics().logEvent('poll_answer');

      const sessionId = state.currentSession ? state.currentSession.id : null;
      const viewerId = rootGetters['auth/getUserId'];

      if (sessionId && pollId) {
        const db = firebase.firestore();
        const pollRef = db
          .collection('sessions')
          .doc(sessionId)
          .collection('polls')
          .doc(pollId);
        db.runTransaction((transaction) => {
          return transaction.get(pollRef).then((doc: any) => {
            if (!doc.exists) {
              throw 'Document does not exist!';
            }
            transaction.set(
              pollRef,
              {
                answers: {
                  [viewerId]: choices,
                },
              },
              { merge: true }
            );
          });
        });
      }
    },
    /**************** Wordclouds **********/
    bindSessionWordcloud: firestoreAction(
      async ({ bindFirestoreRef }, { sessionId }) => {
        const db = firebase.firestore();
        const ref = db
          .collection('sessions')
          .doc(sessionId)
          .collection('wordclouds');
        await bindFirestoreRef('sessionWordclouds', ref, {
          wait: true,
        });
      }
    ),
    async submitWord({ state }, { word, wordcloudId }) {
      const sessionId = state.currentSession ? state.currentSession.id : null;
      if (sessionId) {
        const db = firebase.firestore();
        // const author = rootGetters['auth/getUserId'];
        const wordcloudRef = db
          .collection('sessions')
          .doc(sessionId)
          .collection('wordclouds')
          .doc(wordcloudId);

        db.runTransaction((transaction) => {
          return transaction.get(wordcloudRef).then((doc: any) => {
            if (!doc.exists) {
              throw 'Document does not exist!';
            }
            //check if key in data
            if (word in doc.data()) {
              transaction.update(wordcloudRef, {
                [word]: doc.data()[word] + 1,
              });
            } else {
              transaction.set(
                wordcloudRef,
                {
                  [word]: 1,
                },
                { merge: true }
              );
            }
          });
        });
      }
    },
  },
};

export { ViewerModule };
