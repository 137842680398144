import Vue from 'vue';
import Vuex from 'vuex';
import createLogger from 'vuex/dist/logger';
// import { i18n } from '../lang/i18n.config';
import { vuexfireMutations } from 'vuexfire';
import {
  AuthModule,
  ErrorHandlingModule,
  LangModule,
  PresenterModule,
  ViewerModule,
  PreferencesModule,
  QuestionModule,
} from './modules';

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production';

const store = new Vuex.Store({
  modules: {
    auth: AuthModule,
    errorHandling: ErrorHandlingModule,
    lang: LangModule,
    presenter: PresenterModule,
    viewer: ViewerModule,
    preferences: PreferencesModule,
    questions: QuestionModule,
  },
  state: {
    isLoading: false,
    errorMessage: '',
  },
  getters: {
    isLoadingIndicatorVisible(state) {
      return state.isLoading;
    },
    getErrorMessage: (state) => state.errorMessage,
  },
  mutations: {
    SET_LOADING(state, payload) {
      state.isLoading = payload;
    },
    SET_ERROR_MESSAGE(state, payload) {
      state.errorMessage = payload;
    },
    RESET(state) {
      state.isLoading = false;
    },
    ...vuexfireMutations,
  },
  actions: {
    toggleLoadingIndicator({ commit }, loadingIndicatorState: boolean) {
      commit('SET_LOADING', loadingIndicatorState);
    },
  },
  strict: debug,
  plugins: debug ? [createLogger()] : [],
});

export { store };
